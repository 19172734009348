// Modal.js
import React from 'react';
import Modal from 'react-modal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { red } from '@mui/material/colors';
import './Modal.css';

Modal.setAppElement('#root');

const CustomModal = ({ isOpen, onRequestClose, message, messageType }) => (
    <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        className="modal"
        overlayClassName="overlay-modal"
    >
        <div className="modal-content">
            <h2>
                {messageType === 'success' ? (
                    <CheckCircleIcon fontSize="large" color="success" />
                ) : (
                    <CancelIcon fontSize="large" sx={{ color: red[500] }} />
                )}
            </h2>
            <p>{message}</p>
            <button onClick={onRequestClose}>
                <CloseIcon fontSize="medium" />
            </button>
        </div>
    </Modal>
);

export default CustomModal;
