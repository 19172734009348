import { Rodrigo } from '../images';
import './about.css';

const About = () => {
    return (
        <section className="about" id='about'>
            <div className="container">
                <h5 className="title-section">
                    Quem somos
                </h5>
                <div className='card'>
                    <img src={Rodrigo} alt="Rodrigo Lobato" />
                    <div>
                        <p className='title'>
                            Rodrigo Lobato
                        </p>
                        <p className='function'>
                            Co-Founder | Analista de Sistemas | Cientista de Dados <br /> Especialista em RPA (<em>Robotic Process Automation</em>)
                        </p>
                        <p className='description'>
                            Sou Rodrigo Lobato, analista de sistemas, cientista de dados, especialista em RPA (<em>Robotic Process Automation</em>) e cofundador da Full Solver Soluções Digitais. Com 30 anos de idade e 7 anos de experiência na área de tecnologia, sempre tive o objetivo de ter o meu próprio negócio. Hoje, na Full Solver, consegui realizar esse sonho com excelência, agregando valores significativos por meio da automação de processos e do auxílio baseado em dados reais nas tomadas de decisões da sua empresa. Conto com uma equipe de desenvolvedores altamente qualificados, prontos para atender suas necessidades, desde a criação de um site simples ou landing page até o desenvolvimento de sistemas robustos e complexos.
                        </p>
                    </div>
                </div>
                {/* <div className='card colum-reverse'>
                    <div>
                        <p className='title'>
                            Matheus Wilberstaedt
                        </p>
                        <p className='function'>
                            Co-Founder | Back-end developer
                        </p>
                        <p className='description'>
                            Com 23 anos de idade e 5 anos de experiência na área de desenvolvimento, sempre tive o sonho de ter minha própria empresa. A Full Solver é a concretização desse sonho. Minha paixão por tecnologia e inovação me impulsionou a criar soluções digitais que realmente fazem a diferença para nossos clientes. Na Full Solver, estou comprometido em entregar projetos de alta qualidade, sempre buscando a excelência e a satisfação dos nossos parceiros.
                        </p>
                    </div>
                    <img src={User} alt="Matheus Wilberstaedt" />
                </div>
                <div className='card'>
                    <img src={User} alt="Leonardo Zianni" />
                    <div>
                        <p className='title'>
                            Leonardo Zianni
                        </p>
                        <p className='function'>
                            Co-Founder | Front-end developer
                        </p>
                        <p className='description'>
                            Tenho 25 anos de idade e 5 anos de experiência no desenvolvimento de soluções web, trabalhando com as tecnologias mais atuais do mercado, como HTML5, CSS3, JavaScript, React e Vue.js. Minha experiência abrange a criação de sites responsivos, landing pages e sistemas complexos, sempre com um foco especial na experiência do usuário (UX/UI) e na otimização para motores de busca (SEO).
                        </p>
                    </div>
                </div> */}
            </div>
        </section>
    )
}

export default About