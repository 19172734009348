import "./service.css";
import WebIcon from '@mui/icons-material/Web';
import TerminalIcon from '@mui/icons-material/Terminal';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CategoryIcon from '@mui/icons-material/Category';
import SmartToyIcon from '@mui/icons-material/SmartToy';

const Services = () => {
  return (
    <section className="services" id="services">
      <div className="container">
        <h2 className="title">Nossos Serviços</h2>
        <div className="services-quality">
          <div className="card">
            <p className="title"><WebIcon fontSize="large" /> Landing Pages e Sites</p>
            <p className="descripition">
              <strong>Design Responsivo:</strong> Garantimos que todas as nossas
              páginas e sites sejam completamente responsivos, adaptando-se
              perfeitamente a diferentes dispositivos e tamanhos de tela.
              <br />
              <br />
              <strong>Otimização para Conversão:</strong> Utilizamos técnicas
              avançadas de design e conteúdo para maximizar a taxa de conversão,
              transformando visitantes em leads ou clientes.
            </p>
          </div>
          <div className="card">
            <p className="title"><TerminalIcon fontSize="large" /> Plataformas Digitais <br /> e Serviços Online</p>
            <p className="descripition">
              <strong>Customização e Escalabilidade:</strong> Desenvolvemos
              plataformas digitais que são altamente personalizáveis e
              escaláveis, ajustando-se às necessidades específicas de
              crescimento do seu negócio.
              <br />
              <br />
              <strong>Segurança e Confiabilidade:</strong> Implementamos padrões
              rigorosos de segurança para proteger os dados dos usuários e
              garantir a confiabilidade contínua das nossas soluções online.
            </p>
          </div>
          <div className="card">
            <p className="title"><AppSettingsAltIcon fontSize="large" /> Desenvolvimento de Aplicativos</p>
            <p className="descripition">
              <strong>Experiência do Usuário (UX) Premium:</strong> Nosso foco
              em UX garante que os aplicativos sejam intuitivos e agradáveis de
              usar, melhorando a satisfação do usuário final.
              <br />
              <br />
              <strong>Desempenho Otimizado:</strong> Desenvolvemos aplicativos
              que combinam funcionalidade avançada com desempenho otimizado,
              garantindo uma experiência fluida e eficiente.
            </p>
          </div>
          <div className="card">
            <p className="title"><StorefrontIcon fontSize="large" /> Desenvolvimento de <br /> Lojas Virtuais</p>
            <p className="descripition">
              <strong>Interface de Compra Intuitiva:</strong> Projetamos
              interfaces de usuário intuitivas que facilitam a navegação e a
              compra, melhorando a experiência de compra online.
              <br />
              <br />
              <strong>Integração Completa de E-commerce:</strong> Implementamos
              soluções completas de e-commerce que incluem desde gestão de
              inventário até integração de sistemas de pagamento seguros.
            </p>
          </div>
          <div className="card">
            <p className="title">
              <CategoryIcon fontSize="large" /> Desenvolvimento de MVP (Minimum Viable Product)
            </p>
            <p className="descripition">
              <strong>Rápido Time-to-Market:</strong> Desenvolvemos MVPs
              para que você possa lançar produtos no mercado mais
              rapidamente e validar conceitos sem grandes investimentos
              iniciais.
              <br />
              <br />
              <strong>Flexibilidade e Iteração:</strong> Permitimos iterações
              rápidas com base no feedback do usuário, adaptando o MVP para
              atender às necessidades e preferências do mercado em constante
              mudança.
            </p>
          </div>
          <div className="card">
            <p className="title"><SmartToyIcon fontSize="large" /> Desenvolvimento e Implementação de RPA</p>
            <p className="descripition">
              <strong>Desenvolvimento de Bots:</strong> Criação de bots
              personalizados para automatizar tarefas específicas.
              <br />
              <br />
              <strong>Integração de Sistemas:</strong> Integração de bots RPA
              com sistemas e aplicações existentes.
              <br />
              <br />
              <strong>Automação de Processos Complexos:</strong> Implementação
              de automação para processos que envolvem múltiplos sistemas e
              etapas.
            </p>
          </div>
        </div>
        <a target="_blank" href="https://wa.me/554888242507?text=Ol%C3%A1!%20eu%20gostaria%20de%20entrar%20para%20o%20mundo%20digital" rel="noopener noreferrer">
          VAMOS CONVERSAR
        </a>
      </div>
    </section>
  );
};

export default Services;