import './quality.css'
import { Qualidade } from '../images'

const Quality = () => {
  return (
    <section className='quality' id='quality'>
      <div className='container'>
        <div className='d-flex'>
          <div>
            <h3 className='title'>Qualidade</h3>
            <p className='description'>
              Oferecemos serviços de alta qualidade em desenvolvimento digital, incluindo landing pages, sites, aplicativos e lojas virtuais, adaptados às necessidades de cada cliente. Nossa equipe experiente combina habilidades técnicas avançadas com as últimas tendências para entregar soluções inovadoras. Priorizamos a personalização, flexibilidade e qualidade em todos os projetos, utilizando práticas robustas e feedback contínuo. Buscamos inovação constante e mantemos parcerias de longo prazo, garantindo suporte e otimizações contínuas para gerar valor duradouro. Estamos comprometidos em superar as expectativas e transformar ideias em realidade de maneira profissional.
            </p>
          </div>
          <div>
            <img src={Qualidade} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Quality