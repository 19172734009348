import './workflow.css';
import { WorkFlow } from '../images'

const Workflow = () => {
    return (
        <section className="workflow" id='workflow'>
            <div className="container">
                <h4 className="title">
                    Workflow
                </h4>
                <p className='text'>
                    Na Full Solver, seguimos um processo estruturado para garantir a excelência em cada projeto. Começamos com o primeiro contato e uma reunião detalhada para entender suas necessidades. Desenvolvemos uma proposta clara, e após sua aprovação, formalizamos o contrato e planejamos o projeto.
                </p>
                <p className='text'>
                    Durante o desenvolvimento, mantemos uma comunicação contínua. Apresentamos versões intermediárias para feedback e realizamos testes rigorosos antes da entrega final. Oferecemos treinamento e suporte inicial, além de suporte contínuo para manter tudo funcionando perfeitamente.
                </p>
                <div className='overflow-image'>
                    <img src={WorkFlow} alt="Fluxo de trabalho da empresa Full Solver" />
                </div>
                <a target='_blank' href="https://wa.me/554888242507?text=Ol%C3%A1!%20eu%20gostaria%20de%20entrar%20para%20o%20mundo%20digital" rel="noreferrer">
                    PRIMEIRO CONTATO
                </a>
            </div>
        </section>
    )
}

export default Workflow