import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './ContactForm.css';
import axios from 'axios';
import CustomModal from './Modal';
import InputMask from 'react-input-mask';

const ContactForm = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            message: '',
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .max(50, 'Deve ter 50 caracteres ou menos.'),
            email: Yup.string()
                .email('Endereço de email inválido.')
                .required('Campo obrigatório.'),
            phone: Yup.string()
                .matches(/^\(\d{2}\)\s?\d{4,5}-\d{4}$/, 'Formato de telefone inválido (ex: (99) 99999-9999).'),
            message: Yup.string()
                .max(500, 'Deve ter 500 caracteres ou menos.'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            axios.post('https://full-solver-send-email.onrender.com/send-email', {
                to: values.email,
                subject: `Mensagem de ${values.name}`,
                text: `Nome: ${values.name}\nEmail: ${values.email}\nTelefone: ${values.phone}\nMensagem: ${values.message}`,
            })
            .then(response => {
                setModalMessage('Email enviado com sucesso!');
                setMessageType('success');
                setModalIsOpen(true);
                resetForm();
                setSubmitting(false);
            })
            .catch(error => {
                setModalMessage('Falha ao enviar o email.');
                setMessageType('error');
                setModalIsOpen(true);
                setSubmitting(false);
            });
        },
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit} className="form">
                <div className="form-field">
                    <input
                        id="name"
                        name="name"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        className={`${formik.touched.name && formik.errors.name ? 'error' : ''} ${formik.values.name ? 'filled' : ''}`}
                    />
                    <label htmlFor="name" className={formik.values.name ? 'filled' : ''}>Nome</label>
                    {formik.touched.name && formik.errors.name ? (
                        <div className="error-message">{formik.errors.name}</div>
                    ) : null}
                </div>

                <div className="form-field">
                    <input
                        id="email"
                        name="email"
                        type="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        className={`${formik.touched.email && formik.errors.email ? 'error' : ''} ${formik.values.email ? 'filled' : ''}`}
                    />
                    <label htmlFor="email" className={formik.values.email ? 'filled' : ''}>E-mail</label>
                    {formik.touched.email && formik.errors.email ? (
                        <div className="error-message">{formik.errors.email}</div>
                    ) : null}
                </div>

                <div className="form-field">
                    <InputMask
                        mask="(99) 99999-9999"
                        id="phone"
                        name="phone"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                        className={`${formik.touched.phone && formik.errors.phone ? 'error' : ''} ${formik.values.phone ? 'filled' : ''}`}
                    >
                        {(inputProps) => <input {...inputProps} />}
                    </InputMask>
                    <label htmlFor="phone" className={formik.values.phone ? 'filled' : ''}>Telefone</label>
                    {formik.touched.phone && formik.errors.phone ? (
                        <div className="error-message">{formik.errors.phone}</div>
                    ) : null}
                </div>

                <div className="form-field">
                    <textarea
                        id="message"
                        name="message"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.message}
                        className={`${formik.touched.message && formik.errors.message ? 'error' : ''} ${formik.values.message ? 'filled' : ''}`}
                    />
                    <label htmlFor="message" className={formik.values.message ? 'filled' : ''}>Mensagem</label>
                    {formik.touched.message && formik.errors.message ? (
                        <div className="error-message">{formik.errors.message}</div>
                    ) : null}
                </div>

                <button type="submit" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? 'Enviando...' : 'Enviar mensagem'}
                </button>
            </form>
            <CustomModal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                message={modalMessage}
                messageType={messageType}
            />
        </>
    );
};

export default ContactForm;
