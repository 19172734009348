import InstagramIcon from '@mui/icons-material/Instagram'
// import LinkedInIcon from '@mui/icons-material/LinkedIn'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import './footer.css'
import { useEffect, useState } from 'react'

const Rodape = () => {

  const [menuHeight, setMenuHeight] = useState(0);

  useEffect(() => {
    // Calcula a altura do menu após a montagem do componente
    const navHeight = document.querySelector('nav').clientHeight;
    setMenuHeight(navHeight);
  }, []);

  const smoothScrollTo = (targetPosition, duration) => {
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition - menuHeight; // Compensação da altura do menu
    let startTime = null;

    const animation = currentTime => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = ease(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    const ease = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t + b;
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    };

    requestAnimationFrame(animation);
  };

  const handleScroll = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const targetPosition = targetElement.offsetTop;
      const duration = 1000; // Tempo de duração da animação em milissegundos
      smoothScrollTo(targetPosition, duration);
    }
  };

  return (
    <footer>
      <div className='container'>
        <div>
          <a className='links-sociais' href="https://www.instagram.com/fullsolver/" target="_blank" rel="noopener noreferrer">
            <InstagramIcon fontSize="large" />
          </a>
          {/* <a className='links-sociais' href="#" target="_blank" rel="noopener noreferrer">
            <LinkedInIcon fontSize="large" />
          </a> */}
          <a className='links-sociais' href="https://wa.me/554888242507?text=Ol%C3%A1!%20eu%20gostaria%20de%20entrar%20para%20o%20mundo%20digital" target="_blank" rel="noopener noreferrer">
            <WhatsAppIcon fontSize="large" />
          </a>
        </div>
        <ul>
          <li>
            <a href="#services" onClick={(e) => handleScroll(e, 'services')}>Serviços</a>
          </li>
          <li>
            <a href="#quality" onClick={(e) => handleScroll(e, 'quality')}>Qualidade</a>
          </li>
          <li>
            <a href="#workflow" onClick={(e) => handleScroll(e, 'workflow')}>Workflow</a>
          </li>
          <li>
            <a href="#about" onClick={(e) => handleScroll(e, 'about')}>Quem somos</a>
          </li>
          <li>
            <a href="#contact" onClick={(e) => handleScroll(e, 'contact')}>Contato</a>
          </li>
        </ul>
        <p>© 2024 Full Solver</p>
      </div>
    </footer>
  )
}

export default Rodape