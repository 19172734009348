import "./App.css";
import About from "./components/About";
import Banner from "./components/Banner"
import Contact from "./components/Contact";
import Footer from "./components/Footer"
import Header from "./components/Header"
import Services from "./components/Services"
import Workflow from "./components/Workflow";
import Quality from "./components/quality"

function App() {
  return (
    <>
      <Header />
      <Banner />
      <Services />
      <Quality />
      <Workflow />
      <About />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
