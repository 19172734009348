import ContactForm from './ContactForm';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './contact.css';

const Contact = () => {
    return (
        <section className="contact" id='contact'>
            <div className="container">
                <p className="title">
                    Entre em contato conosco para descobrir como podemos ajudar sua empresa a alcançar seus objetivos de forma eficiente e inovadora.
                </p>
                <div className='d-flex'>
                    <div>
                        <p className='cta-msg'>Contato via E-mail</p>
                        <ContactForm />
                    </div>
                    <div>
                        <p className='cta-msg'>Contato via WhatsApp</p>
                        <a href="https://wa.me/554888242507?text=Ol%C3%A1!%20eu%20gostaria%20de%20entrar%20para%20o%20mundo%20digital" target="_blank" rel="noopener noreferrer">
                            <WhatsAppIcon /> WhatsApp
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact