import React, { useState, useEffect } from 'react';
import { Logo } from '../images';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import './header.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuHeight, setMenuHeight] = useState(0);

  useEffect(() => {
    // Calcula a altura do menu após a montagem do componente
    const navHeight = document.querySelector('nav').clientHeight;
    setMenuHeight(navHeight);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const smoothScrollTo = (targetPosition, duration) => {
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition - menuHeight; // Compensação da altura do menu
    let startTime = null;

    const animation = currentTime => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = ease(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    const ease = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t + b;
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    };

    requestAnimationFrame(animation);
  };

  const handleScroll = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const targetPosition = targetElement.offsetTop;
      const duration = 1000; // Tempo de duração da animação em milissegundos
      smoothScrollTo(targetPosition, duration);
      setIsMenuOpen(false); // Fechar o menu após clicar no link
    }
  };

  return (
    <header>
      <nav>
        <a href="#topo" onClick={(e) => handleScroll(e, 'topo')}>
          <img src={Logo} alt="Logo Full Solver" />
        </a>
        <button className="menu-icon" onClick={toggleMenu}>
          {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </button>
        <ul className={isMenuOpen ? 'open' : ''}>
          <li>
            <a href="#services" onClick={(e) => handleScroll(e, 'services')}>Serviços</a>
          </li>
          <li>
            <a href="#quality" onClick={(e) => handleScroll(e, 'quality')}>Qualidade</a>
          </li>
          <li>
            <a href="#workflow" onClick={(e) => handleScroll(e, 'workflow')}>Workflow</a>
          </li>
          <li>
            <a href="#about" onClick={(e) => handleScroll(e, 'about')}>Quem somos</a>
          </li>
          <li>
            <a href="#contact" onClick={(e) => handleScroll(e, 'contact')}>Contato</a>
          </li>
        </ul>
        {isMenuOpen && <div className="overlay" onClick={toggleMenu}></div>}
      </nav>
    </header>
  );
};

export default Header;
